import React, { useState, useEffect } from 'react';
import * as REQUEST from "../common/request";
import './index.less';
import { message } from 'antd';


const PageModel = props => {
    const [list, setList] = useState([]);

    const getList = async () => {
        const result = await REQUEST.get({
            url: '/chouqian/list',
        });
        if (!result) return;

        setList(result.data);
    }


    useEffect(() => {
        getList();
    }, [123]);

    const reset = async (id) => {
        console.log('id===', id);
        const result = await REQUEST.post({
            url: '/chouqian/update',
            data: {
                "name": id,
            }
        });
        console.log('result==', result.data);
        if(result.ok == 0){
            message.success('重置成功');
            getList();
        }
    }

   

    return (
        <section className="choujiangfuwei-page">
            {list.length > 0 && list.map((item, index) => {
                return (
                    <div className="choujiangfuwei-item" key={index}>
                        <div className='first'>第{item.id}轮 <div className='reset' onClick={() => reset(item.id)}>重置</div></div>
                        <div className='two'>
                            {
                                item.data.length > 0 && item.data.map((item2, index2) => {
                                    return (
                                        <div className="choujiangfuwei-item-child" key={index2}>
                                            {/* className={`button-main${props.disabled && ' disabled' || ''}`}  */}
                                            <div className='firstnum'>第{index2 + 1}个人：</div>
                                            <div className={`choujiangfuwei-item-child-num ${item2.flag == 1 ? ' disabled' : ''}`}>{item2.num}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            })}
        </section>
    );
};

export default PageModel;
