import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import * as REQUEST from "../common/request";
import './index.less';


let interval;
const PageModel = props => {
	const [data, setData] = useState(0);

	const getList = async () => {
			const result = await REQUEST.get({
				url: '/chouqian/list',
			});
			if (!result) return;
	}
 	useEffect(() => {
		getList();
	}, [123]);
	const start = async (e) => {
		if (interval) {
			const result = await REQUEST.get({
				url: '/chouqian/getnum',
			});
			if (!result) return;
			if (result.data) {
				setData(result.data);
				clearInterval(interval);
				interval = null;
			} else {
				clearInterval(interval);
				interval = null;
				setData(Math.floor(Math.random() * 8) + 1);
			}
		} else {
			interval = setInterval(() => {
				let randomNum = Math.floor(Math.random() * 8) + 1;
				setData(randomNum);
			}, 50);
		}
	};
	const stop = () => {
		message.success('已复位');
		setData(0);
		interval && clearInterval(interval);
		interval = null;
	};
	return (
		<section className="choujiang-page">
			<div className="choujiang-header">
				<img src={require('./logo.jpg')} />
				<div className="choujiang-header-title">长三角一体化城市管理综合行政执法技能大赛知识竞赛抽签</div>
			</div>
			<div className='choujiang-btn'>
				<div className="choujiang-title" onClick={start}>抽签</div>
				<div className="choujiang-content">{data}</div>
			</div>
			
			<div className='choujiang-time'>
				<div className="choujiang-title" onClick={stop}>复位</div>
			</div>
		</section>
	);
};

export default PageModel;
